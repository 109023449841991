import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { h4, h5 } from './chakra/TypographyDefinitions';
import { MhyChakraProps } from '../types';

interface Props {
  children: ReactNode;
}

export const compactWysiwygStyles = {
  'h2, h3, h4, h5, h6': {
    textStyle: 'lg',
    marginBottom: '0.2rem',
  },
  'p, ul, ol, li': {
    marginBottom: '0.2rem',
    textStyle: 'md',
  },
};

const WysiwygStyles = ({
  children,
  sx = {},
  ...chakraProps
}: Props & Omit<MhyChakraProps, 'variant'>) => (
  <Box
    width="100%"
    {...chakraProps}
    sx={{
      h2: { ...h4, pt: '.5em' },
      'h3, h4, h5, h6': h5,
      'h2, h3, h4, h5, h6, p, ul, ol': {
        marginBottom: '1.5rem',
      },
      'ul, ol': {
        marginLeft: 0,
      },
      ul: {
        listStyleType: 'disc',
      },
      ol: {
        listStyleType: 'decimal',
      },
      figure: {
        marginTop: '2rem',
        marginBottom: '2rem',
        figCaption: {
          py: '2',
          fontSize: '0.9em',
        },
      },
      a: {
        textDecoration: 'underline',
      },
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default WysiwygStyles;
